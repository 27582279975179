import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import gstyles from '@vl/gstyles';
import CustomTable from '@uz/unitz-components-web/CustomTable';

const View8 = () => {
  return (
    <DIV className="component">
      {renderPropsComposer(
        {
          matcher: (props) => !_.get(props, 'isLoading') && !_.get(props, 'items.length'),
          render: () => (
            <Row justify="center" className="py-16">
              <Col span={12}>
                <div className="flex flex-col items-center justify-center">
                  <div className="mb-4">
                    {gstyles.icons({
                      name: 'courseEmpty',
                      size: 152,
                    })}
                  </div>
                  <div className="mb-6 text-center text-2xl font-semibold text-sub">
                    {ctx.apply('i18n.t', 'ZoomToolMember.empty_course_state_title')}
                  </div>
                </div>
              </Col>
            </Row>
          ),
        },
        {
          matcher: (props) => !!_.get(props, 'items.length'),
          render: () => (
            <CustomTable
              columns={ctx.get('tableColumn.columns')}
              dataSource={ctx.get('tableColumn.dataSource', [])}
              rowKey={(rec) => _.get(rec, 'id')}
              loading={_.get(props, 'isLoading', false)}
              pagination={ctx.get('tableColumn.pagination')}
              onChange={ctx.get('paginationModel.onChange')}
              size="middle"
            />
          ),
        },
        () => null
      )(ctx.get('paginationModel'))}
    </DIV>
  );
};

export default displayName(View8);
