import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseModel from '@uz/unitz-models/B2BCourseModel';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import moment from 'moment';
import gstyles from '@vl/gstyles';
import i18n from 'i18n-js';
import { START_TIME_WITHOUT_DAY_OF_WEEK, TIME_HOUR_MINUTE_ML_FORMAT } from '@vl/mod-utils/datetime';
import Link from '@uz/unitz-components-web/Link';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            paginationModel: hook((ctx) => {
              const user_id = _.get(useRoute(), 'params.id', '');
              const ref = React.useRef({});
              const account_id = ctx.apply('accountModel.getAccountId');
              const pagingModel = React.useMemo(() => {
                const condition = `where: {
                  account_id: {_eq: "${account_id}"}
                  course_teacher: { user_id: { _eq: "${user_id}" } }
                }`;
                const dataQuery = `
                  b2b_course (${condition}, order_by: {created_at: desc_nulls_last}){
                    id
                    name
                    photo_url
                    session_duration
                    account_id
                    materials
                    start_at
                    link_urls
                    status
                    course_rooms (order_by: {start_at: asc_nulls_last}) {
                      id
                      name
                      photo_url
                      description
                      start_at
                      end_at
                      status
                      link_urls
                      materials
                      join_url
                    }
                  }
                `;

                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseModel,
                  dataQuery,
                  aggQuery: `
                    b2b_course_aggregate (${condition}) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 4,
                });
                return pagingModel;
              }, [ref, account_id, user_id]);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };

              return pagination;
            }),

            allColumns: hook((ctx) => {
              const locale = i18n.locale;
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        {ctx.get('paginationModel.page') * ctx.get('paginationModel.pageSize') + index + 1}
                      </div>
                    );
                  },
                  width: 80,
                  fixed: 'left',
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.name')}
                    </div>
                  ),
                  render: (val) => {
                    const statusStyle = {
                      draft: gstyles.colors.powerPeony,
                      upcoming: gstyles.colors.powerPeony,
                      ongoing: gstyles.colors.yellow500,
                      finished: gstyles.colors.brandb500,
                      completed: gstyles.colors.brandb500,
                    };
                    return (
                      <div className="identify-container">
                        <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', val)}>
                          <div className="uppercase text-brandb500 underline text-xs">
                            {CourseFormatter.displayName(ctx)(val)}
                          </div>
                        </Link>
                        <div
                          style={{ backgroundColor: _.get(statusStyle, _.get(val, 'status', ''), '') }}
                          className={`my-2 capitalize inline-block text-xs font-semibold text-white500 rounded-lg text-center px-2 py-1`}
                        >
                          {ctx.apply('i18n.t', `ZoomToolCourse.Status.${_.get(val, 'status', '')}`)}
                        </div>
                        <div className="text-xs">
                          {ctx.apply('i18n.t', 'ZoomToolCourse.Table.startDate')}:{' '}
                          {moment(_.get(val, 'start_at', '')).format(
                            _.get(START_TIME_WITHOUT_DAY_OF_WEEK, locale, 'de')
                          )}
                        </div>
                      </div>
                    );
                  },
                  width: 300,
                  ellipsis: true,
                  require: true,
                  fixed: 'left',
                },
                ..._.map(_.range(0, 4, 1), (index) => {
                  return {
                    title: <div className="text-center text-sub font-semibold text-xs">Lesson {index + 1}</div>,
                    render: (val) => {
                      const lessonData = _.get(val, `course_rooms.${index}`);
                      const start_at = _.get(lessonData, 'start_at', '');
                      const duration = `${moment(start_at).format(
                        _.get(TIME_HOUR_MINUTE_ML_FORMAT, locale, 'de')
                      )} - ${moment(_.get(lessonData, 'end_at', '')).format(
                        _.get(TIME_HOUR_MINUTE_ML_FORMAT, locale, 'de')
                      )}`;
                      return (
                        <div className="lesson-item text-sub text-center">
                          {!!_.get(lessonData, 'start_at', '') ? (
                            <div className="text-xs">
                              <div>{moment(start_at).format('dddd')},</div>
                              {moment(start_at).format(_.get(START_TIME_WITHOUT_DAY_OF_WEEK, locale, 'de'))}
                              <div>{duration}</div>
                            </div>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      );
                    },
                  };
                }),
              ];
              return columns;
            }),

            tableName: hook(() => 'tblTeacherCoursesReport'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');
              const tableName = ctx.get('tableName', 'tblTeacherCoursesReport');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
