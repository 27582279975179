import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Row, Col } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import InputText from '@uz/unitz-components-web/InputText';
import { Form, FormItem, Checkbox } from 'formik-antd';
import gstyles from '@vl/gstyles';
import './modal.scss';
import Button from '@uz/unitz-components-web/Button';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import _ from 'lodash';

const layout = {
  labelCol: { span: 4, offset: 0 },
  wrapperCol: { span: 20, offset: 2 },
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const View8 = () => {
  return (
    <DIV className="component">
      <Modal
        title={<div className="text-main text-xl font-semibold">{ctx.get('modalModel.title')}</div>}
        visible={ctx.get('modalModel.isModalVisible')}
        onOk={ctx.get('form.submitForm')}
        onCancel={ctx.get('modalModel.handleCancel')}
        wrapClassName={'my-modal'}
        className="rounded-lg overflow-hidden"
        maskStyle={{ background: gstyles.colors.black300 }}
        destroyOnClose
        centered
        width={688}
        closeIcon={<div className="mt-4">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>}
        afterClose={() => ctx.apply('modalModel.handleCancel')}
        footer={[
          <Row className="mb-4" justify="end" key="footer">
            <Col span={20}>
              <Row gutter={16}>
                <Col span={12}>
                  <Button
                    name="ink"
                    block
                    onClick={ctx.get('modalModel.handleCancel')}
                    loading={ctx.get('form.isSubmitting')}
                  >
                    {ctx.apply('i18n.t', 'ZoomToolMember.Edit_Form.Action.cancel')}
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    key="submit"
                    type="primary"
                    block
                    onClick={ctx.get('form.submitForm')}
                    disabled={!ctx.get('form.canSubmit')}
                    loading={ctx.get('form.isSubmitting')}
                  >
                    {ctx.apply('i18n.t', 'ZoomToolMember.Action.send')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>,
        ]}
      >
        <FormProvider form={ctx.get('form')}>
          <Form id="send-mail-form">
            <div className="text-sub font-semibold text-sm mb-6">
              {ctx.apply('i18n.t', 'ZoomToolMember.Form.send_to_label')}:
            </div>
            <Row>
              <Col span={6}>
                <div className="text-sub font-semibold text-sm">
                  {ctx.apply('i18n.t', 'Login.email')} <span className="text-red500">*</span>
                </div>
              </Col>
              <Col span={18}>
                {_.map(ctx.get('form.values.emails'), (mail, index) => {
                  return (
                    <Row align="middle" key={_.get(mail, 'id', '')} gutter={gutter}>
                      <Col span={index === 0 ? 24 : 22}>
                        <CustomInput>
                          <FormItem name={`emails[${index}].content`}>
                            <InputText
                              name={`emails[${index}].content`}
                              size="medium"
                              placeholder="alexhuge@gmail.com"
                            />
                          </FormItem>
                        </CustomInput>
                      </Col>
                      {index === 0 ? null : (
                        <Col span={2}>
                          <div
                            className="cursor-pointer inline-block"
                            onClick={() => ctx.apply('form.array.remove', 'emails', index)}
                          >
                            {gstyles.icons({
                              name: 'remove-circle',
                              fill: gstyles.colors.red500,
                              size: 24,
                            })}
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
                })}
                <Row className="my-1" gutter={gutter}>
                  <Col span={16}>
                    <Button
                      type="outline"
                      size="small"
                      className="w-full"
                      onClick={() => ctx.apply('form.array.add', 'emails')}
                      icon={gstyles.icons({
                        name: 'add-circle',
                        size: 20,
                        fill: gstyles.colors.brandb500,
                        className: 'inline-block mx-2',
                      })}
                    >
                      {ctx.apply('i18n.t', 'ZoomToolMember.Action.add_email')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <CustomInput>
              <FormItem name="check_send_me">
                <Checkbox name="check_send_me">{ctx.apply('i18n.t', 'ZoomToolMember.Form.send_to_me')}</Checkbox>
              </FormItem>
            </CustomInput>
          </Form>
        </FormProvider>
      </Modal>
    </DIV>
  );
};

export default displayName(View8);
