import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';

const View8 = () => {
  return (
    <DIV className="component">
      <Row className="px-12" gutter={[24, 16]} justify="center" align="middle" wrap>
        {_.map(ctx.get('items'), (card, index) => (
          <Col key={index} span={8}>
            <div className="p-4 rounded-lg border border-border">
              <div className="text-sub mb-2 font-normal text-sm">{_.get(card, 'title', '')}</div>
              <div className="text-main font-semibold text-xl">{_.get(card, 'data', '')}</div>
            </div>
          </Col>
        ))}
      </Row>
    </DIV>
  );
};

export default displayName(View8);
