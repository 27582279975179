import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            itemData: hook((ctx) => {
              return ctx.get('@item.user_info');
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
